<template>
    <div class="class-manage-resources">
        <div class="select-wrapper">
            <div class="select-left" style="height: 41px"></div>
            <div class="select-btn">
                <el-button type="primary" @click="addSchool">创建学校</el-button>
            </div>
        </div>
        <el-table :data="schoolListTable" border style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa'}" class="table-content" :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column prop="scl_name" align="center" label="学校名称"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button size="small" type="success" @click="toCourses(scope.row)" >分配课程</el-button>
                    <el-button size="small" type="success" @click="toProject(scope.row)" >分配项目</el-button>
                    <el-button size="small" type="success" @click="toApp(scope.row)" >分配应用</el-button>
                    <el-button size="small" type="primary" @click="editBtn(scope.row, scope.row.scl_id)">编辑</el-button>
                    <el-button size="small" type="danger" @click="deleteBtn(scope.row.scl_id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-right"
                       :current-page="schoolPages.currentPageNum"
                       :page-size="schoolPages.eachPageNum"
                       :total="schoolPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="schoolCurrentChange">
        </el-pagination>
        <!--创建学校弹窗-->
        <el-dialog :title="schoolOperTitle" :visible.sync="addSchoolDialog" center width="30%" @close="resetForm()" @opened="openAddSchool" :close-on-click-modal="false">
            <el-form :model="addSchoolForm" ref="addSchoolForm" :rules="schoolRules" label-position="left">
                <el-form-item label="学校名称" :label-width="formLabelWidth" prop="scl_name">
                    <el-input v-model="addSchoolForm.scl_name" ref="collegeInput" autocomplete="off" placeholder="1-20字符"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="addSchoolDialog = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="addToForm('addSchoolForm')">确 定</el-button>
            </div>
        </el-dialog>
        <!--分配项目弹窗-->
        <el-dialog title="分配项目" :visible.sync="assignProjectDialog" center width="846px" @close="resetProjectForm()" :close-on-click-modal="false">
            <div class="assign-project-list">
                <el-scrollbar class="class-list" :native="false">
                    <el-tree :data="classList" :props="classProps" @node-click="handleClassClick" class="class-tree"></el-tree>
                </el-scrollbar>
                <el-scrollbar class="project-list" :native="false">
                    <div class="project-item" v-for="item in projectLists">
                        <el-checkbox-group v-model="selectProjectList" @change="selectProject" class="checkbox-group">
                            <el-checkbox :label="item.id" class="checkbox-label"></el-checkbox>
                        </el-checkbox-group>
                        <span class="project-name ellispase-1">{{item.project_title}}</span>
                    </div>
                    <div v-if="projectLists.length === 0" class="no-data">
                        <span>暂无数据</span>
                    </div>
                </el-scrollbar>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="assignProjectDialog = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="submitAssignProject">确 定</el-button>
            </div>
        </el-dialog>
        <!--分配应用弹窗-->
        <el-dialog title="分配应用" :visible.sync="assignAppDialog" center width="846px" @close="resetAppForm()" :close-on-click-modal="false">
            <div class="assign-project-list">
                <el-scrollbar class="project-list app-list" :native="false">
                    <div class="project-item" v-for="item in appLists" :class="{current: currentApp === item.id}">
                        <div class="project-text">
                            <el-checkbox-group v-model="selectAppList" @change="selectApp($event, item)" class="checkbox-group">
                                <el-checkbox :label="item.id" class="checkbox-label"></el-checkbox>
                            </el-checkbox-group>
                            <!--@click="selectApps(item)"-->
                            <span class="project-name" @click="selectApps(item)">{{item.app_name}}</span>
                        </div>
                        <i class="iconfont">&#xe608;</i>
                    </div>
                    <!--<div v-if="appLists.length === 0" class="no-data">-->
                        <!--<span>暂无数据</span>-->
                    <!--</div>-->
                </el-scrollbar>
                <el-scrollbar class="class-list app-form-list" :native="false">
                    <el-form :model="editAppForm" ref="editAppForm" :rules="editAppRules" label-position="center" class="info-form">
                        <el-form-item label="名称：" :label-width="appFormLabelWidth" prop="app_name">
                            <el-input v-model="editAppForm.app_name" :disabled="editStatus" autocomplete="off" placeholder="请输入名称"  class="app-input"></el-input>
                        </el-form-item>
                        <el-form-item label="简介：" :label-width="appFormLabelWidth" prop="intro">
                            <el-input v-model="editAppForm.intro" :disabled="editStatus" autocomplete="off" placeholder="请输入简介"  class="app-input"></el-input>
                        </el-form-item>
                        <el-form-item label="首页链接：" :label-width="appFormLabelWidth" prop="home_link">
                            <el-input v-model="editAppForm.home_link" :disabled="editStatus" autocomplete="off" placeholder="请输入首页链接"  class="app-input"></el-input>
                        </el-form-item>
                        <el-form-item label="商家链接：" :label-width="appFormLabelWidth" prop="shop_link">
                            <el-input v-model="editAppForm.shop_link" :disabled="editStatus" autocomplete="off" placeholder="请输入商家链接"  class="app-input"></el-input>
                        </el-form-item>
                        <el-form-item label="教师链接：" :label-width="appFormLabelWidth" prop="teacher_link">
                            <el-input v-model="editAppForm.teacher_link" :disabled="editStatus" autocomplete="off" placeholder="请输入教师链接"  class="app-input"></el-input>
                        </el-form-item>
                        <el-form-item label="logo：" :label-width="appFormLabelWidth" prop="logo" class="el-form-item-logo">
                            <div class="upload-cover">
                                <i class="iconfont">&#xe659;</i>
                                <span>{{editAppForm.logo ? '重新上传' : '上传图片'}}</span>
                            </div>
                            <input type="file" accept="image/*" title="" ref="alogo" @change="changeEditLogo($event)" class="cover-input">
                            <div class="up-img-show" v-show="appLogo">
                                <img :src="appLogo" alt />
                            </div>
                        </el-form-item>
                    </el-form>
                </el-scrollbar>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="assignAppDialog = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="submitAssignApp('editAppForm')">确 定</el-button>
            </div>
        </el-dialog>
        <!--分配课程弹窗-->
        <el-dialog title="分配课程" :visible.sync="courseDialog" center width="846px"
                   :close-on-click-modal="false" @open="courseCategories" @close="resetAdminCourseForm()">
            <div class="course-class" style="margin-bottom: 10px;">
                <el-cascader v-model="courseCategoryId" :props="courseCateProps"
                             :options="courseCategoryList" @change="handleCourseCategory" clearable></el-cascader>
            </div>
            <div class="assign-project-list">
                <el-scrollbar class="class-list course-lists" :native="false">
                    <el-cascader-panel
                            v-model="selectCourseList"
                            :options="optionsCourse"
                            :key="courseKey"
                            :props="propsCourse"
                            @change="selectCourseChange"
                            clearable
                    class="course-cascader"></el-cascader-panel>
                </el-scrollbar>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="resetAdminCourseForm">取 消</el-button>
                <el-button size="medium" type="primary" @click="submitAssignCourse">确 定</el-button>
            </div>
        </el-dialog>
        <!--<CoursesDialogWrapper v-if="courseDialog" :school-id="divideSchoolId" @closeDialog="closeCourseDialog"></CoursesDialogWrapper>-->
    </div>
</template>

<script>
    import CoursesDialogWrapper from "./CoursesDialogWrapper";
    import {categoryList, courseList} from "@/utils/apis";
    import _ from "underscore";
    export default {
        components: {
            CoursesDialogWrapper
        },
        data() {
            return {
                //分类列表
                classList: [],
                classProps: {
                    children: 'children',
                    label: 'rc_name'
                },
                //课程列表
                propsCourse: { multiple: true, value: 'course_id', label: 'course_name'},
                optionsCourse: [],
                //已选的课程列表
                selectCourseList: [],

                schoolListTable: [],
                schoolPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                addSchoolDialog: false,
                schoolOperTitle: '',
                formLabelWidth: '80px',
                addSchoolForm: {
                    scl_id: '',
                    scl_name: ''
                },
                schoolRules: {
                    scl_name: [
                        { required: true, message: '请输入学校名称', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ]
                },
                distinguishBtn: '',
                courseDialog: false,
                divideSchoolId: null,
                //是否显示分配项目弹窗
                assignProjectDialog: false,
                //最后一级分类
                theLastLevel: null,
                //项目列表
                projectLists: [],
                //已选的项目集合
                selectProjectList: [],
                //修改应用表单数据
                editAppForm: {
                    parent_id: '',
                    app_name: '',
                    intro: '',
                    home_link: '',
                    shop_link: '',
                    teacher_link: '',
                    logo: '',
                },
                editAppRules:{
                    app_name: [
                        { required: true, message: '请输入名称', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ],
                    intro: [
                        { required: true, message: '请输入简介', trigger: 'blur' },
                        { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
                    ],
                    home_link: [
                        { required: true, message: '请输入首页链接', trigger: 'blur' },
                        { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
                    ],
                    shop_link: [
                        { required: true, message: '请输入商家链接', trigger: 'blur' },
                        { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
                    ],
                    teacher_link: [
                        { required: true, message: '请输入教师链接', trigger: 'blur' },
                        { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
                    ],
                },
                appFormLabelWidth: '100px',
                //是否显示分配应用弹窗
                assignAppDialog: false,
                //应用列表
                appLists: [],
                currentApp: null,
                //已选的应用集合
                selectAppList: [],
                //已分配的应用
                assignAppOne: [],
                //要分配的新的应用
                assignAppTwo: [],
                currentTmp: [],
                //学校应用id
                apps: [],
                //已选择的要分配给学校的应用
                selectedSchoolApp: [],
                singleSchoolApp: {},
                //提交分配时是否点击"确定"按钮
                isClick: false,
                applistTmp: [],
                appLogo: null,
                courseCategoryList: [],
                courseCategoryId: [],
                courseCateProps: {
                    label: "rc_name",
                    value: "rc_id"
                },
                courseKey: 0,
                tmpCourseList: [],
                middleArr:[],
            }
        },
        mounted() {
            this.getSchoolList();
        },
        computed: {
            editStatus() {
                if (this.selectAppList.indexOf(this.currentApp) !== -1) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        watch: {
            optionsCourse() {
                this.courseKey++
            },
            selectCourseList(newvalue, oldvalue)
            {
                return oldvalue.concat(newvalue);
            }
        },
        methods: {
            courseCategories() {
                categoryList({type: 3}).then(res => {
                    this.courseCategoryList = res.list.map(item => {
                        if (item.children && item.children.length > 0) {
                            return {
                                "rc_id": item.rc_id,
                                rc_name: item.rc_name,
                                children: item.children.map(value => {
                                    return {
                                      "rc_id": value.rc_id,
                                      "rc_name": value.rc_name
                                    }
                                })
                            }
                        }else{
                            return {
                                "rc_id": item.rc_id,
                                rc_name: item.rc_name,
                            }
                        }
                    })   
                }).catch(err => {
                    console.log('err', err);
                })
            },
            handleCourseCategory(value) {
                const loadingStatus = this.$loading({
                  lock: true,
                  text: '加载中...',
                  spinner: 'el-icon-loading',
                });
                let param = {type: 1}
                if (value.length) {
                  param = {...param, classId: value[value.length - 1]}
                }
                // this.tmpCourseList = this.tmpCourseList.concat(this.selectCourseList);
                courseList(param).then(res => {
                    loadingStatus.close()
                    if (res.code === 200) {
                        let courseList = [];
                        let tmpObj = {};
                        let childrenObj = {};
                        if (!res.list.length) {
                            this.optionsCourse = [];
                            return;
                        }
                        for (let i = 0; i< res.list.length; i ++) {
                          let childrenArr = res.list[i].course_data.course_content;
                          tmpObj = {
                            course_id: res.list[i].course_id,
                            course_name: res.list[i].course_name,
                            course_type: res.list[i].course_type,
                            // children: res.list[i].course_data.course_content
                            children: []
                          }
                          for (let j = 0; j < childrenArr.length; j++) {
                            childrenObj = {
                              course_name: `第 ${j+1} 章 ${childrenArr[j].name}`,
                              course_id: j
                            }
                            tmpObj.children.push(childrenObj)
                          }
                          courseList.push(tmpObj);
                        }
                        this.optionsCourse = courseList;
                        
                        this.courseSelectedValidate(courseList);
                        
                    } else {
                        this.$message.warning(res.msg)
                    }
                }).catch(err => {
                    console.log('err', err)
                })
            },
            courseSelectedValidate(courseList) {
              const courseIds = this.$common.arrayUnique(this.selectCourseList);
              let courseIdList = courseList.map(item => {
                  return item.course_id
              })
              courseIds.map(item => {
                  if (courseIdList.indexOf(item) === -1) {
                      this.selectCourseList.map(val => {
                          console.log('val', val);
                          if (Number(val[0]) === Number(item)) {
                              this.tmpCourseList.push(val);
                          }
                      })
                  }
              })
            },
            getSchoolList() {
                let params = {
                    page: this.schoolPages.currentPageNum,
                    limit: this.schoolPages.eachPageNum,
                }
                this.$http.axiosGetBy(this.$api.showSchool, params, (res) => {
                    if (res.code === 200) {
                        this.schoolListTable = res.data.data;
                        this.schoolPages.total = res.data.total
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            schoolCurrentChange(val) {
                this.schoolPages.currentPageNum = val;
                this.getSchoolList();
            },
            addSchool() {
                this.addSchoolDialog = true;
                this.schoolOperTitle = '创建学校';
                this.distinguishBtn = 'add';
            },
            editBtn(row, id) {
                this.addSchoolDialog = true;
                this.schoolOperTitle = '编辑学校';
                this.distinguishBtn = 'edit';
                this.addSchoolForm.scl_id = id;
                this.addSchoolForm.scl_name = row.scl_name;
            },
            addToForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.distinguishBtn === 'add') {
                            let formData = new FormData();
                            formData.append('scl_name', this.addSchoolForm.scl_name)
                            this.$http.axiosPost(this.$api.addSchool, formData, (res) => {
                                if (res.code === 200) {
                                    this.$message.success(res.msg);
                                    this.schoolPages.currentPageNum = 1;
                                    this.getSchoolList();
                                    this.addSchoolDialog = false;
                                    this.resetForm();
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        } else if (this.distinguishBtn === 'edit') {
                            let formData = new FormData();
                            formData.append('scl_id', this.addSchoolForm.scl_id);
                            formData.append('scl_name', this.addSchoolForm.scl_name);
                            this.$http.axiosPost(this.$api.saveSchool, formData, (res) => {
                                if (res.code === 200) {
                                    this.$message.success(res.msg);
                                    this.addSchoolDialog = false;
                                    this.getSchoolList();
                                    this.resetForm();
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            deleteBtn(id) {
                this.$confirm('此操作将永久删除该学校, 是否继续?', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let formData = new FormData();
                    formData.append('scl_id', id);
                    this.$http.axiosPost(this.$api.delSchool, formData, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.getSchoolList();
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            resetForm() {
                this.$refs.addSchoolForm.resetFields();
                this.addSchoolForm = {
                    scl_id: '',
                    scl_name: ''
                }
            },
            openAddSchool() {
                this.$refs.collegeInput.focus();
            },
            toCourses(school) {
                this.divideSchoolId = school.scl_id;
                this.courseDialog = true;
                this.getCourseLists();
                this.getSchoolInfo(school.scl_id);
            },
            //获取学校信息
            getSchoolInfo(id) {
                this.$http.axiosGet(this.$api.schoolList, (res) => {
                    if (res.code === 200) {
                        let dataArr = res.data;
                        let course_data = []
                        let project_id = []
                        for (let i = 0; i < dataArr.length; i++) {
                            if (id === dataArr[i].scl_id) {
                                if (dataArr[i].course_data) {
                                    course_data = dataArr[i].course_data;
                                }
                                if (dataArr[i].pro_ids) {
                                    if (dataArr[i].pro_ids === 'null') {

                                    } else {
                                        let reg = new RegExp('NaN,')
                                        let string = dataArr[i].pro_ids.replace(reg,"");
                                        project_id = string.split(',').map(Number);
                                    }
                                }
                            }
                        }
                        console.log(course_data)
                        if (course_data.length > 0) {
                            this.selectCourseList = JSON.parse(course_data);
                            this.middleArr = JSON.parse(course_data);
                        }
                        this.selectProjectList = project_id;
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            in_array(course_id){
                if (this.selectedIds.indexOf(course_id) !== -1) {
                    return true;
                } else {
                    return false;
                }
            },
            closeCourseDialog(val) {
                this.courseDialog = false;
                if (val) {
                    let params = {
                        scl_id: this.divideSchoolId,
                        cid_belongs: val.join(',')
                    }
                    this.$http.axiosPost(this.$api.divideClass, params, (res) => {
                        if (res.code === 200) {
                            this.$message({
                                type: 'success',
                                message: '分配成功',
                                duration: 1000
                            });
                        } else {
                            this.$message({
                                type: 'warning',
                                message: res.msg,
                                duration: 1000
                            });
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }
                this.divideSchoolId = null;
            },
            //获取课程列表
            getCourseLists() {
                let params = {
                    parent_id: 0,
                    type: 1,
                    sort: 1
                }
                this.$http.axiosGetBy(this.$api.admin_courseLists, params, (res) => {
                    if (res.code === 200) {
                        let tmpObj = {};
                        let childrenObj = {};
                        if (this.optionsCourse.length === 0) {
                            for (let i = 0; i< res.list.length; i ++) {
                                let childrenArr = res.list[i].course_data.course_content;
                                tmpObj = {
                                    course_id: res.list[i].course_id,
                                    course_name: res.list[i].course_name,
                                    course_type: res.list[i].course_type,
                                    // children: res.list[i].course_data.course_content
                                    children: []
                                }
                                for (let j = 0; j < childrenArr.length; j++) {
                                    childrenObj = {
                                        course_name: `第 ${j+1} 章 ${childrenArr[j].name}`,
                                        course_id: j
                                    }
                                    tmpObj.children.push(childrenObj)
                                }
                                this.optionsCourse.push(tmpObj)
                            }
                        }
                    }
                }, (err) => {
                    console.log(err);
                });
            },
            //选择的课程
            selectCourseChange(val) {
                // this.middleArr=this.selectCourseList
                // // tmpCourseList长度大于0，selectCourseList合并tmpCourseList的数据
                // if (this.tmpCourseList.length > 0) {
                //     this.selectCourseList=this.selectCourseList.concat(this.tmpCourseList);
                //     // this.middleArr=this.selectCourseList
                //     this.tmpCourseList = [];
                // }else if(this.selectCourseList.length>0){
                //     // 当selectCourseList大于0，直接合并外部勾选的数据
                //    this.selectCourseList.concat(val)
                // //    this.selectCourseList=this.selectCourseList.concat(this.middleArr)
                // }
                
            },
            //获取项目分类列表
            getProjectClassList() {
                this.$http.axiosGetBy(this.$api.resourceClassList, {type: 4}, (res) => {
                    if (res.code === 200) {
                        this.classList = res.list;
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //分配项目
            toProject(row) {
                this.assignProjectDialog = true;
                this.addSchoolForm.scl_id = row.scl_id;
                this.getProjectClassList();
                this.getSchoolInfo(row.scl_id);
            },
            //重置分配项目弹窗
            resetProjectForm() {

            },
            //分配应用
            toApp(row) {
                this.assignAppDialog = true;
                this.addSchoolForm.scl_id = row.scl_id;
                this.getAppLists();
                if (row.appcenter_ids) {
                    this.apps = JSON.parse(row.appcenter_ids);
                }
                let oneApp = {}
                if (this.apps && this.apps.length > 0) {
                    for (let k = 0; k < this.apps.length; k++) {
                        oneApp = this.apps[k];
                        this.$http.axiosGetBy(this.$api.app_showApp, {id: this.apps[k].school_app_id}, (res) => {
                            let assign = res.data.data[0];
                            if (res.code === 200) {
                                this.applistTmp.push({
                                    id: assign.id,
                                    app_name: assign.app_name,
                                    intro: assign.intro,
                                    home_link: assign.home_link,
                                    shop_link: assign.shop_link,
                                    teacher_link: assign.teacher_link,
                                    logo: assign.logo,
                                    app_tag: assign.app_tag,
                                    parent_id: assign.parent_id
                                });
                                this.selectAppList.push(assign.parent_id);
                            }
                        }, (err) => {
                            console.log(err);
                        })
                    }
                }
            },
            handleClassClick(data) {
                if (data.level === 3) {
                    this.theLastLevel = data.rc_id;
                    this.getProjectList();
                }
            },
            //获取分类下的项目列表
            getProjectList() {
                let param = {
                    resource_class_id: this.theLastLevel,
                    type: 1
                }
                this.$http.axiosGetBy(this.$api.project_AdminProjectList, param, (res) => {
                    if (res.code === 200) {
                        this.projectLists = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //选择的项目
            selectProject(val) {
                this.selectProjectList = val;
            },
            //提交分配项目
            submitAssignProject() {
                let formData = new FormData();
                formData.append('scl_id', this.addSchoolForm.scl_id);
                if (this.selectProjectList.length > 0) {
                    formData.append('pro_ids', this.selectProjectList.join(','));
                } else {
                    formData.append('pro_ids', null);
                }

                this.$http.axiosPost(this.$api.admin_allocationProject, formData, (res) => {
                    if (res.code === 200) {
                        this.$message.success(res.msg);
                        this.assignProjectDialog = false;
                        this.selectProjectList = [];
                        this.getSchoolInfo(this.addSchoolForm.scl_id);
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //获取平台应用列表
            getAppLists() {
                let param = {
                    type: 'all',
                    parent_id: 0
                }
                this.$http.axiosGetBy(this.$api.app_showApp, param, (res) => {
                    if (res.code === 200) {
                        this.appLists = res.data;
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //重置分配应用弹窗
            resetAppForm() {
                this.editAppForm = {
                    parent_id: '',
                    app_name: '',
                    intro: '',
                    home_link: '',
                    shop_link: '',
                    teacher_link: '',
                    logo: '',
                };
                this.applistTmp = [];
                this.appLogo = null;
                this.selectAppList = [];
                this.apps = null;
            },
            //选择的应用
            selectApp(val, item) {
                let tmpList = this.applistTmp;
                this.applistTmp = [];
                this.selectAppList = val;
                _.each(val, (key) => {
                    let isTmp = _.find(tmpList, { parent_id: key});
                    if (isTmp) {
                        this.applistTmp.push(isTmp);
                    } else {
                        let isHas = _.find(this.appLists, { id: key});
                        let obj = Object.assign({}, isHas);
                        obj.parent_id = isHas.id;
                        delete obj.id;
                        delete obj.create_time;
                        delete obj.update_time;
                        this.applistTmp.push(obj);
                    }
                });
            },
            setAppAttr(app) {
                _.each(this.applistTmp, (element, index) => {
                    if (Number(element.parent_id) === Number(app.parent_id)) {
                        this.applistTmp[index] = { ...element, ...app };
                    }
                });
            },
            //切换应用
            selectApps(item) {
                this.currentApp = item.id;
                let info = null;
                if (this.editAppForm.app_name) {
                    let appInfo = Object.assign({}, this.editAppForm);
                    this.setAppAttr(appInfo);
                }
                let tmp = _.find(this.applistTmp, { parent_id: item.id });
                if (tmp) {
                    info = tmp;
                } else {
                    info = Object.assign({}, item);
                }

                this.editAppForm.parent_id = info.parent_id ? info.parent_id : info.id;
                this.editAppForm.app_name = info.app_name;
                this.editAppForm.intro = info.intro;
                this.editAppForm.home_link = info.home_link;
                this.editAppForm.shop_link = info.shop_link;
                this.editAppForm.teacher_link = info.teacher_link;
                this.editAppForm.logo = info.logo;
                if (info.logo.src) {
                    this.appLogo = info.logo.src;
                } else {
                    this.getLogo(info.logo);
                }
            },
            //上传logo
            changeEditLogo(event) {
                if (this.editStatus) {
                   this.$message.warning('请先勾选该应用');
                   this.$refs.alogo.value = '';
                   return ;
                }

                if (event.target.files.length === 0) return;
                let fileList = event.target.files[0];
                this.editAppForm.logo = fileList;
                let url = '';
                let reader = new FileReader();
                reader.readAsDataURL(fileList);
                let that = this;
                reader.onload = function (e) {
                    url = this.result.substring(this.result.indexOf(',') + 1);
                    that.appLogo = "data:image/png;base64," + url;
                }
                event.target.value = '';
            },
            getLogo(file) {
                let url = '';
                let reader = new FileReader();
                reader.readAsDataURL(file);
                let that = this;
                reader.onload = function (e) {
                    url = this.result.substring(this.result.indexOf(',') + 1);
                    that.appLogo = "data:image/png;base64," + url;
                }
            },
            //提交分配应用
            submitAssignApp(formName) {
                if (this.editAppForm.app_name) {
                    this.setAppAttr(this.editAppForm);
                }
                //应用的参数规范
                for (let i = 0; i < this.applistTmp.length; i++) {
                    let item = this.applistTmp[i];
                    if (Number(item.app_name) === 0 || item.app_name.length < 3) {
                        this.$message.warning(`【${item.app_name}】- 应用名称必须不得小于3个字，请重新设置`);
                        return;
                    }
                    let strRegex= '(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]';
                    let reg = new RegExp(strRegex);
                    if (!reg.test(item.home_link)) {
                        this.$message.warning(`【${item.app_name}】- 应用首页链接不规范，请重新设置`);
                        return;
                    }
                    if (item.shop_link && !reg.test(item.shop_link)) {
                        this.$message.warning(`【${item.app_name}】- 应用商家链接不规范，请重新设置`);
                        return;
                    }
                    if (item.teacher_link && !reg.test(item.teacher_link)) {
                        this.$message.warning(`【${item.app_name}】- 应用教师链接不规范，请重新设置`);
                        return;
                    }
                }
                new Promise(resolve => {
                    let data = [];
                    if (this.applistTmp.length > 0) {
                        for(let i = 0; i < this.applistTmp.length; i++) {
                            let post_data = this.applistTmp[i];
                            let form_data = new FormData();
                            if (post_data.id) {
                                form_data.append('id', post_data.id);
                            }
                            form_data.append('app_name', post_data.app_name);
                            form_data.append('intro', post_data.intro);
                            form_data.append('home_link', post_data.home_link);
                            form_data.append('shop_link', post_data.shop_link);
                            form_data.append('teacher_link', post_data.teacher_link);
                            if (post_data.logo && !post_data.logo.src && post_data.logo.size) {
                                form_data.append('logo', post_data.logo);
                            }
                            form_data.append('app_tag', post_data.app_tag);
                            form_data.append('parent_id', post_data.parent_id);
                            this.$http.axiosPost(this.$api.app_saveApp, form_data, (res) => {
                                if (res.code === 200) {
                                    let data_tmp = {
                                        parent_id: post_data.parent_id,
                                        school_app_id: post_data.id ? post_data.id : Number(res.data.id) //此处要考虑编辑的状态返回的是true，要自己添加id
                                    };
                                    data.push(data_tmp);
                                    if (data.length === this.applistTmp.length) {
                                        resolve(data);
                                    }
                                }
                            }, (err) => {
                                this.$common.axiosErrorMsg(err);
                            });
                        }
                    } else {
                        resolve(data);
                    }
                }).then(res => {
                    let param = new FormData();
                    param.append('scl_id', this.addSchoolForm.scl_id);
                    param.append('appcenter_ids', JSON.stringify(res));
                    this.$http.axiosPost(this.$api.admin_allocationAppCenter, param, (res) => {
                        if (res.code === 200) {
                            this.$message({
                                type: 'success',
                                message: res.msg,
                                duration: 1000,
                                onClose: () => {
                                    this.assignAppDialog = false;
                                    this.getSchoolList();
                                }
                            });
                        }
                    }, (err) =>{
                        this.$common.axiosErrorMsg(err);
                    });
                });
            },
            //提交分配课程
            submitAssignCourse() {
                let selectCourseId = []
                let resultArr = []
                let tempArr=[...this.selectCourseList]
                for (let i = 0; i < this.selectCourseList.length; i++) {
                    resultArr.push(this.selectCourseList[i][0]);
                    selectCourseId = resultArr.filter( function  (item, index, self) {
                        return  self.indexOf(item) === index;
                    });
                }
                console.log(tempArr.length);
                if(this.courseCategoryId.length>0){
                    for(let i=0;i<this.middleArr.length;i++){
                        if(!this.selectCourseList.some(el=>{
                            return el[0]==this.middleArr[i][0]&&el[1]==this.middleArr[i][1];
                        })){
                            tempArr.push(this.middleArr[i]);
                        }
                    }
                }
                console.log(tempArr.length);
                let params = {
                    scl_id: this.divideSchoolId,
                    cid_belongs: selectCourseId.join(','),
                    course_data: JSON.stringify(tempArr)
                }
                this.selectCourseList = params.course_data;
                this.$http.axiosPost(this.$api.divideClass, params, (res) => {
                    if (res.code === 200) {
                        this.resetAdminCourseForm();
                        this.$message({
                            type: 'success',
                            message: '分配成功',
                            duration: 1000
                        });
                        
                    } else {
                        this.$message({
                            type: 'warning',
                            message: res.msg,
                            duration: 1000
                        });
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //重置
            resetAdminCourseForm() {
                this.courseDialog = false;
                this.selectCourseList = [];
                this.courseCategoryId = [];
                this.optionsCourse = [];
            }
        }
    }
</script>

<style scoped lang="scss">
    .select-wrapper {
        display: flex;
        justify-content: space-between;
    }
    .assign-project-list {
        display: flex;
        height: 450px;
        max-height: 450px;
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        border-radius: 5px;
    }
    .class-list {
        height: 100%;
        width: 60%;
        border-right: 1px solid #DDDDDD;
        ::v-deep .el-scrollbar__wrap {
            height: 100%;
            overflow-x: hidden;
        }
        .class-tree {
            /*width: 100%;*/
            padding: 15px;
            ::v-deep .el-tree-node__content {
                height: 37px;
            }
        }

        &.course-lists {
            width: 100%;
            ::v-deep .el-scrollbar__wrap {
                height: 100%;

                .el-scrollbar__view {
                    height: 100%;
                    .course-cascader {
                        height: 100%;
                        .el-cascader-menu__wrap {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
    .app-form-list {
        border-right: 1px solid transparent;
        .info-form {
            padding: 15px;
            .el-form-item-logo {
                margin-bottom: 0;
            }
            .upload-cover {
                position: relative;
                background-color: #DCECFE;
                border-color: #DCECFE;
                padding: 0;
                width: 80px;
                height: 80px;
                color: #409EFF;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                font-size: 12px;
                border-radius: 10px;
                i {
                    width: 16px;
                    height: 16px;
                    margin: 5px;
                }
            }
            .cover-input {
                position: absolute;
                width: 80px;
                height: 80px;
                top: 0;
                opacity: 0;
                cursor: pointer;
            }
            .up-img-show {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                width: 80px;
                height: 80px;
                top: 0;
                left: 100px;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
        .app-input {
            width: 330px;
        }
    }
    .app-list {
        border-right: 1px solid #DDDDDD;
        .project-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            &:nth-child(1) {
                margin-top: 13px;
                padding-top: 0 !important;
            }
            .project-text {
                display: flex;
                height: 100%;
                width: 100%;
                .project-name {
                    display: block;
                    width: calc(100% - 40px);
                }
            }
        }
        .current {
            background-color: #f5f7fa;
        }
    }
    .project-list {
        height: 100%;
        width: 40%;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
            .el-scrollbar__view {
                height: 100%;
            }
        }
        .project-item {
            display: flex;
            height: 37px;
            line-height: 37px;
            padding: 0 15px;
            &:nth-child(1) {
                padding-top: 13px;
            }
            .checkbox-group {
                margin-right: 10px;
                .checkbox-label {
                    ::v-deep .el-checkbox__label {
                        display: none;
                    }
                }
            }

            .project-name.ellispase-1 {
                display: inline-block;
                white-space: nowrap;         /* 禁止换行 */
                overflow: hidden;            /* 隐藏超出容器的部分 */
                text-overflow: ellipsis;
            }
        }
        .no-data {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }
    }
</style>